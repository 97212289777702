import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { zepAnalytics } from '../utils/analytics';

import styles from './Footer.module.scss';

export default function Footer() {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <footer className={styles.footer_container}>
      <div className={styles.main_info_container}>
        <img
          className={styles.logo_img}
          src={'/assets/quizLogoBeta.svg'}
          alt="zepquiz"
        />
        <img
          className={styles.logo_small}
          src={'/assets/logo_small_q.svg'}
          alt="logo"
        />
        <div className={styles.menus_wrapper}>
          <a
            href={`https://zep.us${
              router.locale ? `/${router.locale}` : ''
            }/accounts/terms`}
            target="_blank"
            onClick={() => {
              zepAnalytics.track('enter_terms', { location: 'footer' });
            }}
            className={styles.menu_item}>
            {t('global.terms')}
          </a>
          <div className={styles.menu_divider}></div>
          <a
            href={`https://zep.us${
              router.locale ? `/${router.locale}` : ''
            }/accounts/privacy`}
            onClick={() => {
              zepAnalytics.track('enter_privacy', { location: 'footer' });
            }}
            target="_blank"
            className={styles.menu_item}>
            {t('global.privacy')}
          </a>
        </div>
      </div>
      <div className={styles.sub_info_container}>{t('footer.value')}</div>
    </footer>
  );
}
